import React, { Component } from 'react'
import classnames from 'classnames'
import { get } from 'lodash'
import Prismjs from 'prismjs'
import 'prismjs/themes/prism.css'
import styles from './BlogArticle.module.scss'
import Helmet from 'react-helmet'
import { ConsumerWrapper } from '../../Context'
import { AuthorTag, Hyphen, Image, BlogCard } from '../../components'

class BlogArticle extends Component {
  componentDidMount () {
    Prismjs.highlightAll()
    this.props.consumerContext.set({
      bgColor: '#FBE6E2'
    })
  }

  componentWillUnmount () {
    this.props.consumerContext.set({
      bgColor: undefined
    })
  }

  render () {
    const {
      pageContext: {
        title,
        content,
        author,
        date,
        intro,
        acf,
        yoastTitle,
        metadesc,
        moreArticles = []
      }
    } = this.props
    const image = get(acf, 'post_main_image.localFile', {})
    const href = this.props.location ? this.props.location.href : null

    return (
      <div className={classnames(styles.container, 'container py-6')}>
        <Helmet>
          <meta property='og:title' content={yoastTitle ? yoastTitle.replace(/&amp;/g, '&') : yoastTitle} />
          {metadesc &&
            <meta property='og:description' content={metadesc} />
          }
          {href &&
            <meta property='og:url' content={href} />
          }
          <meta property='og:type' content='website' />
          {image.banner && image.banner.large &&
            <meta property='og:image' content={image.banner.large.src} />
          }
        </Helmet>
        <div className='row'>
          <div className='col-md-10 offset-md-1 mb-4'>
            {author && (
              <AuthorTag
                avatar={get(author, 'avatar_urls.wordpress_96', '')}
                name={author.name}
                date={date}
                className='mb-3'
              />
            )}
            <h1><Hyphen>{title}</Hyphen></h1>
            {intro && <h3>{intro}</h3>}
            {image.banner && (
              <Image
                image={image.banner}
                ratio={0.620183}
              />
            )}
          </div>
        </div>
        <div
          className={classnames(styles.content, 'row')}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <section className='mt-6 row'>
          <div className='offset-lg-1 col-lg-10'>
            <h2>Lesa meira stöff</h2>
            <div className='row'>
              {moreArticles.map(article => (
                <div
                  key={article.node.slug}
                  className='col-md-6 mb-4'
                >
                  <BlogCard
                    title={article.node.title}
                    author={article.node.author}
                    date={article.node.date}
                    href={'/blog/' + article.node.slug}
                    image={get(article, 'node.acf.post_main_image.localFile.childImageSharp', {})}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default ConsumerWrapper(BlogArticle, ['set'])
